
/**
 * The invite URL to the Royal Game of Ur Discord server.
 */
export const DISCORD_INVITE_URL = "https://discord.gg/FbCp76esgb";

/**
 * The URL of the RoyalUr.net Facebook page.
 */
export const FACEBOOK_PAGE_URL = "https://www.facebook.com/profile.php?id=100092622646545";

/**
 * The URL of the RoyalUr.net Instagram page.
 */
export const INSTAGRAM_PAGE_URL = "https://www.instagram.com/royalur_net";

/**
 * The URL of the Reddit for the Royal Game of Ur.
 */
export const REDDIT_URL = "https://www.reddit.com/r/GameofUr/";

/**
 * The URL of the RoyalUr.net Twitter account.
 */
export const TWITTER_URL = "https://twitter.com/royalur_net";

/**
 * The affiliate URL for Masters Traditional Games.
 */
export const MASTERS_AD_URL_PARAMS = "?utm_source=royalur&utm_medium=referral&utm_campaign=urad";
export const MASTERS_AD_URL = "https://www.mastersofgames.com/cat/board/board-games-historical.htm" + MASTERS_AD_URL_PARAMS;

/**
 * The URL of my Ko-Fi.
 */
export const KOFI_URL = "https://ko-fi.com/padraiglamont";

/**
 * The canonical URL of this website!
 */
export const ROYALUR_URL = "https://royalur.net";

/**
 * The URL for the company behind RoyalUr.net, Rosette Games.
 */
export const ROSETTE_URL = "https://rosettegames.com/";

/**
 * Email for contacting us about the game.
 */
export const CONTACT_US_EMAIL = "support@rosettegames.com";

/**
 * Email URL for contacting us about the game.
 */
export const CONTACT_US_MAILTO_URL = "mailto:" + CONTACT_US_EMAIL;

/**
 * The address of the RoyalUr.net business.
 */
export const BUSINESS_ADDRESS = "71-75 Shelton Street, Covent Garden, London, WC2H 9JQ";

/**
 * Tom Scott vs. Irving Finkel on YouTube!
 */
export const TOM_SCOTT_VIDEO_URL = "https://www.youtube.com/watch?v=WZskjLq040I";

/**
 * The amount of time to wait before an account is deleted.
 */
export const ACCOUNT_DELETION_DELAY_HOURS = 3 * 24;
